import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, NavLink } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from './tri_mate_logo.png'; // Adjust the path as needed
import { Container, Navbar, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { Box, Grid, Typography, Stack, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import AdapterDateFns from '@mui/x-date-pickers/AdapterDateFns';

import HomePage from './HomePage';
import PaceCalculationPage from './PaceCalculationPage';
import TrainingPlanPage from './TrainingPlanPage';
import PoseEstimationPage from './PoseEstimationPage';
import NutritionCalculationPage from './NutritionCalculationPage';

// Custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#00796b',
    },
    secondary: {
      main: '#ff3d00',
    },
    background: {
      default: '#f5f5f5',
      paper: '#ffffff',
    },
    text: {
      primary: '#212121',
      secondary: '#757575',
    },
  },
  typography: {
    fontFamily: 'Arial',
    h5: {
      fontWeight: 600,
    },
  },
});

function App() {
  const [distanceType, setDistanceType] = useState('');
  const [distanceUnit, setDistanceUnit] = useState('');
  const [raceDate, setRaceDate] = useState('');
  const [longTrainingDay, setLongTrainingDay] = useState('');
  const [swimTime, setSwimTime] = useState(null);
  const [bikeTime, setBikeTime] = useState(null);
  const [runTime, setRunTime] = useState(null);
  const [swimPace, setSwimPace] = useState(null);
  const [bikePace, setBikePace] = useState(null);
  const [runPace, setRunPace] = useState(null);

  const handleDistanceSelection = (event) => {
    setDistanceType(event.target.value);
  };

  const handleDistanceUnitChange = (event) => {
    setDistanceUnit(event.target.value);
  };

  const handleLongTrainingDayChange = (event) => {
    setLongTrainingDay(event.target.value);
  };

  const handleSwimTimeChange = (newValue) => {
    setSwimTime(newValue);
  };

  const handleBikeTimeChange = (newValue) => {
    setBikeTime(newValue);
  };

  const handleRunTimeChange = (newValue) => {
    setRunTime(newValue);
  };

  const handleSwimPaceChange = (newValue) => {
    setSwimPace(newValue);
  };

  const handleBikePaceChange = (event) => {
    setBikePace(event.target.value);
  };

  const handleRunPaceChange = (newValue) => {
    setRunPace(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div>
          <Navbar bg="light" expand="lg">
            <Navbar.Brand as={Link} to="/">
              <img
                src={logo}
                height="50"
                className="d-inline-block align-top"
                alt="Logo"
              />
              TriMate
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link as={NavLink} to="/" exact activeClassName="active">Home</Nav.Link>
                <Nav.Link as={NavLink} to="/training-plan" activeClassName="active">Training Plan</Nav.Link>
                <Nav.Link as={NavLink} to="/nutrition-plan" activeClassName="active">Nutrition Plan</Nav.Link>
                <Nav.Link as={NavLink} to="/pace-calculation" activeClassName="active">Pace Calculation</Nav.Link>
                <Nav.Link as={NavLink} to="/pose-estimation" activeClassName="active">Pose Estimation</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <Container fluid>
            <Routes>
              <Route path="/pace-calculation" element={<PaceCalculationPage />} />
              <Route path="/training-plan" element={<TrainingPlanPage />} />
              <Route path="/pose-estimation" element={<PoseEstimationPage />} />
              <Route path="/nutrition-plan" element={<NutritionCalculationPage />} />
              <Route path="/" element={<HomePage />} />
            </Routes>
          </Container>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
